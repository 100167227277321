<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <v-container>
      <v-menu rounded="lg">
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            small
            text
            class="pl-4 pr-4 text-capitalize white mr-3"
            v-bind="attrs"
            v-on="on"
            outlined=""
          >
            {{ $store.state.whichToShow }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item
              v-for="item in gameItems"
              :key="item"
              @click="$store.dispatch('wichToSwitch', item)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <div v-if="$store.state.admin != ''" class="mt-1" style="font-size: 15px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div v-if="$store.state.whichToShow == 'bingo'">
          <v-layout
            row
            wrap
            class="text-center"
            id="dashboard"
            v-if="!pageLoading"
            justify-space-around
          >
            <v-flex xs12 md4>
              <a>
                <v-card
                  outlined
                  class="ma-2 pt-10 pb-10 pa-7 pr-1 grey--text text--darken-2"
                  height="230px"
                >
                  <v-layout row wrap>
                    <v-flex xs10>
                      <h4>Today's Bingo Report &nbsp;</h4>
                      <br />
                      <span
                        >Total Number of games:
                        <u
                          ><strong>{{ bingoTodaysWork.length }}</strong></u
                        ></span
                      >
                      <br />
                      <span
                        >Total Money:
                        <strong>{{ bingoTodayTotal.toFixed(2) }}</strong>
                        Birr</span
                      ><br />

                      <span
                        >Winner's Money:
                        <strong>{{ bingoWinnersMoney.toFixed(2) }}</strong>
                        Birr</span
                      ><br />

                      <span
                        >Total Profit:
                        <strong>
                          {{
                            (bingoTodayTotal - bingoWinnersMoney).toFixed(2)
                          }}</strong
                        >
                        Birr</span
                      ><br />
                    </v-flex>
                    <v-flex xs2>
                      <v-avatar size="35" class="blue lighten-1">
                        <v-icon class="white--text text--lighten-1"
                          >trending_up</v-icon
                        >
                      </v-avatar>
                    </v-flex>
                  </v-layout>
                </v-card>
              </a>
            </v-flex>
          </v-layout>

          <div
            v-if="!pageLoading"
            style="border-radius: 3px"
            class="white pt-3 mt-8"
          >
            <v-layout row wrap justify-space-around="">
              <v-flex xs12 md5>
                <div id="chooseDate" class="ml-10 mr-10 pl-10">
                  <h4 class="mt-6 grey--text text--darken-1 ml-10">
                    Select Bingo Report Date
                  </h4>

                  <v-menu rounded="lg" offset-y>
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        text
                        class="pl-6 pr-6 mt-2"
                        v-bind="attrs"
                        v-on="on"
                        outlined=""
                      >
                        {{ bingoChoosedDateRange }}
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item-group
                        v-model="bingoGroup"
                        active-class="deep-purple--text text--accent-4"
                      >
                        <v-list-item
                          v-for="item in bingoItems"
                          :key="item"
                          @click="
                            if (item == 'Custom Range') {
                              dateDialog = true;
                            } else bingoGetDailyWorks(item);
                          "
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </div>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="mb-15 pa-3 pt-0">
              <horizontal-scroll
                id="report"
                class="horizontal-scroll white pa-3 pt-0"
                style="border-radius: 5px"
              >
                <table style="width: 100%" class="ma-3 mt-0">
                  <tr>
                    <th>Day</th>
                    <th>No Games</th>

                    <th>Total Money</th>
                    <th>Winners Money</th>

                    <th>Total Profit</th>
                  </tr>
                  <tr v-for="(dailyReport, i) in bingoDailyReport" :key="i">
                    <td style="font-size: 11px">
                      <v-icon small>date_range</v-icon>
                      {{ weekday[new Date(dailyReport.day).getDay()] }}
                      <br v-if="dailyReport.date != ''" />
                      <span class="ml-4">{{ dailyReport.date }}</span>
                    </td>
                    <td>
                      {{ dailyReport.noTicket }}
                    </td>

                    <td>
                      {{ dailyReport.totalMoney.toFixed(2) }}
                      <span style="font-size: 11px">ETB</span>
                    </td>
                    <td>
                      {{ dailyReport.winnersMoney.toFixed(2) }}
                      <span style="font-size: 11px">ETB</span>
                    </td>

                    <td>
                      {{ dailyReport.totalProfit.toFixed(2) }}
                      <span style="font-size: 11px">ETB</span>
                    </td>
                    <hr />
                  </tr>
                </table>
              </horizontal-scroll>
            </v-layout>
          </div>
        </div>

        <v-dialog
          v-model="depositDialog"
          persistent
          max-width="420"
          v-if="partnerBalance != ''"
        >
          <v-card class="pa-1">
            <v-card-title>Deposit Available </v-card-title>
            <v-card-text>
              <v-layout
                row
                wrap
                class="pt-3"
                v-for="(deposit, i) in partnerBalance.getDeposit"
                :key="i"
              >
                <v-flex v-if="i != 0" xs12 class="pa-1">
                  <v-divider></v-divider>
                </v-flex>
                <v-flex xs6>
                  <span>
                    <strong>Name:</strong>
                    {{ partnerBalance.partner_full_name }}</span
                  >
                  <br />
                  <span>
                    <strong>Date:</strong>
                    {{
                      new Date(Number(deposit.deposit_date)) | formatDate
                    }}</span
                  >
                </v-flex>

                <v-flex xs4>
                  <span> <strong> Amount: </strong>{{ deposit.amount }}</span
                  ><br />
                  <span>
                    <strong>Transaction ID:</strong>
                    {{ deposit.transaction_id }}</span
                  >
                </v-flex>

                <v-flex xs2 class="pt-2">
                  <a @click="confirmDeposit(deposit)" class="mb-1">
                    <span class="text-capitalize blue--text">Confirm</span>
                  </a>
                  <br />
                  <a @click="rejectDeposit(deposit)" class="">
                    <span class="text-capitalize red--text">Reject</span>
                  </a>
                </v-flex>
              </v-layout>
            </v-card-text>

            <v-form @submit.prevent="" class="pl-4 pr-4">
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="depositDialog = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>

        <v-dialog v-model="jackpotDialog" persistent max-width="800">
          <v-card class="pa-1">
            <v-card-title class="text-capitalize">
              {{ $store.state.whichToShow }} Jackpot list <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="red"
                text
                @click="jackpotDialog = false"
              >
                Cancel
              </v-btn></v-card-title
            >
            <v-card-text v-if="$store.state.whichToShow == 'Dogs'">
              <v-layout row wrap class="mb-15 pa-3 pt-0">
                <horizontal-scroll
                  id="report"
                  class="horizontal-scroll white pa-3 pt-0"
                  style="border-radius: 5px"
                >
                  <table style="width: 100%" class="ma-3 mt-0">
                    <tr>
                      <th>Date</th>
                      <th>Bet</th>
                      <th>Amount</th>
                      <th>Type</th>

                      <th>Cashier</th>
                      <th>Status</th>
                    </tr>
                    <tr v-for="(jack, i) in kenoJackHistory" :key="i">
                      <td style="font-size: 11px">
                        <span class="ml-4">{{
                          new Date(Number(jack.jack_date)) | formatDate
                        }}</span>
                      </td>
                      <td>
                        {{ jack.selected_no }}
                      </td>
                      <td>
                        {{ jack.amount }}
                      </td>
                      <td>
                        {{ jack.jack_type }}
                      </td>
                      <td>
                        {{
                          jack.getCashier.length > 0
                            ? jack.getCashier[0].cashier_full_name
                            : ""
                        }}
                      </td>
                      <td>
                        {{ jack.is_paid == 1 ? "Paid" : "Not paid" }}
                      </td>

                      <hr />
                    </tr>
                  </table>
                </horizontal-scroll>
              </v-layout>
            </v-card-text>

            <v-card-text v-if="$store.state.whichToShow == 'bingo'">
              <v-layout row wrap class="mb-15 pa-3 pt-0">
                <horizontal-scroll
                  id="report"
                  class="horizontal-scroll white pa-3 pt-0"
                  style="border-radius: 5px"
                >
                  <table style="width: 100%" class="ma-3 mt-0">
                    <tr>
                      <th>Date</th>
                      <th>Bet</th>
                      <th>Amount</th>
                      <th>Type</th>

                      <th>Cashier</th>
                      <th>Status</th>
                    </tr>
                    <tr v-for="(jack, i) in bingoJackHistory" :key="i">
                      <td style="font-size: 11px">
                        <span class="ml-4">{{
                          new Date(Number(jack.jack_date)) | formatDate
                        }}</span>
                      </td>
                      <td>
                        {{ jack.selected_no }}
                      </td>
                      <td>
                        {{ jack.amount }}
                      </td>
                      <td>
                        {{ jack.jack_type }}
                      </td>
                      <td>
                        {{
                          jack.getCashier.length > 0
                            ? jack.getCashier[0].cashier_full_name
                            : ""
                        }}
                      </td>
                      <td>
                        {{ jack.is_paid == 1 ? "Paid" : "Not paid" }}
                      </td>

                      <hr />
                    </tr>
                  </table>
                </horizontal-scroll>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="logoutReportDialog"
          persistent
          max-width="420"
          v-if="logoutReport.length > 0"
        >
          <v-card class="">
            <v-card-title>
              Cashier logout report <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="red"
                text
                @click="logoutReportDialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                :loading="clearLoading"
                text
                @click="clearCashierReport"
              >
                Clear
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-layout row wrap justify-space-around style="font-size: 13px">
                <v-flex
                  xs9
                  v-for="report in logoutReport"
                  :key="report.logout_report_id"
                  class="ma-2"
                >
                  <span>Cashier Name: {{ report.cashier_name }}</span
                  ><br />
                  <span
                    >Login Time:
                    {{
                      new Date(Number(report.loggin_time)) | formatDate
                    }}</span
                  ><br />
                  <span
                    >Logout Time:
                    {{
                      new Date(Number(report.logout_time)) | formatDate
                    }}</span
                  ><br />
                  <span>Location: {{ report.location }}</span
                  ><br />
                  <span>IP Address: {{ report.ip_address }}</span
                  ><br />
                  <span>Total tickets: {{ report.total_tickets }}</span
                  ><br />
                  <span>Paid tickets: {{ report.paid_tickets }}</span
                  ><br />
                  <span>Cancelled tickets: {{ report.cancelled_tickets }}</span
                  ><br />
                  <span>Number of Bets: {{ report.number_bets }}</span
                  ><br />
                  <span>Total money: {{ report.total_money }}</span
                  ><br />
                  <span>Paid money: {{ report.paid_money }}</span
                  ><br />
                  <span>On Hand money: {{ report.onhand_money }}</span
                  ><br /><br />
                  <hr />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="attemptReportDialog"
          persistent
          max-width="420"
          v-if="attemptReport.length > 0"
        >
          <v-card class="">
            <v-card-title>
              Login attempt report <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="red"
                text
                @click="attemptReportDialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                class="text-capitalize"
                dark
                color="pink"
                :loading="clearLoading"
                text
                @click="clearLoginReport"
              >
                Clear
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-layout row wrap justify-space-around style="font-size: 13px">
                <v-flex
                  xs9
                  v-for="attempt in attemptReport"
                  :key="attempt.login_try_id"
                  class="ma-2"
                >
                  <span>User Name: {{ attempt.user_name }}</span
                  ><br />
                  <span>Password: {{ attempt.password }}</span
                  ><br />

                  <span>Location: {{ attempt.location }}</span
                  ><br />
                  <span>IP Address: {{ attempt.ip_address }}</span
                  ><br />
                  <span
                    >Login trying Time:
                    {{
                      new Date(Number(attempt.login_time)) | formatDate
                    }}</span
                  ><br />
                  <hr class="mt-1" />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="inputAndWinnersFrequencyPopUp"
          persistent
          max-width="420"
          v-if="inputAndWinnersFrequencyPopUp == true"
        >
          <v-card class="">
            <v-card-title>
              <span v-if="$store.state.whichToShow == 'Dogs'"
                >Dogs frequency</span
              >
              <span v-else>bingo frequency</span>
              <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="red"
                text
                @click="inputAndWinnersFrequencyPopUp = false"
              >
                Cancel
              </v-btn></v-card-title
            >

            <v-card-text>
              <v-layout
                row
                wrap
                justify-space-around
                style="font-size: 13px"
                v-if="$store.state.whichToShow == 'Dogs'"
              >
                <v-flex xs5 class="ma-2">
                  <p>Input frequency</p>
                  <div v-for="input in inputMoneyFrequency" :key="input.input">
                    <span>
                      {{ input.input }} Birr &nbsp;&nbsp;
                      {{ input.frequency }} Bets </span
                    ><br />
                  </div>
                </v-flex>
                <v-flex xs5 class="ma-2">
                  <p>Winners frequency</p>
                  <div
                    v-for="winner in winnerMoneyFrequency"
                    :key="winner.winnnerMoney"
                  >
                    <span>
                      {{ winner.winnnerMoney.toFixed(2) }} Birr &nbsp;&nbsp;
                      {{ winner.frequency }} Winners </span
                    ><br />
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                justify-space-around
                style="font-size: 13px"
                v-if="$store.state.whichToShow == 'bingo'"
              >
                <v-flex xs5 class="ma-2">
                  <p>Input frequency</p>
                  <div
                    v-for="input in bingoInputMoneyFrequency"
                    :key="input.input"
                  >
                    <span>
                      {{ input.input }} Birr &nbsp;&nbsp;
                      {{ input.frequency }} Bets </span
                    ><br />
                  </div>
                </v-flex>
                <v-flex xs5 class="ma-2">
                  <p>Winners frequency</p>
                  <div
                    v-for="winner in bingoWinnerMoneyFrequency"
                    :key="winner.winnnerMoney"
                  >
                    <span>
                      {{ winner.winnnerMoney }} Birr &nbsp;&nbsp;
                      {{ winner.frequency }} Winners </span
                    ><br />
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="unpaidDialog" persistent max-width="600">
          <v-card class="pt-5">
            <v-card-title>
              Unpaid Tickets <v-spacer></v-spacer>

              <v-btn
                class="text-capitalize"
                dark
                color="red"
                text
                @click="unpaidDialog = false"
              >
                Cancel
              </v-btn></v-card-title
            >

            <v-card-text>
              <v-layout row wrap class="text-center">
                <v-flex xs6 class="pa-2">
                  <span class="black--text text-center">Dogs ticket#</span
                  ><br />
                  <span v-for="keno in kenoUnpaidList" :key="keno"
                    >#{{ keno }} <br />
                  </span>
                </v-flex>
                <!-- <v-flex xs6 class="pa-2">
                  <span class="black--text text-center">bingo ticket#</span
                  ><br />
                  <span v-for="bingo in bingoUnpaidList" :key="bingo"
                    >#{{ bingo }} <br />
                  </span>
                </v-flex> -->
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dateDialog" persistent max-width="420">
          <v-card class="">
            <v-card-title>
              Choose date range <v-spacer></v-spacer>
              <v-btn
                class="text-capitalize"
                dark
                color="blue"
                text
                @click="
                  dateDialog = false;
                  if (date1 != '' && date2 != '') {
                    if ($store.state.whichToShow == 'Dogs') {
                      getDailyWorks('Custom Range');
                    } else if ($store.state.whichToShow == 'bingo') {
                      bingoGetDailyWorks('Custom Range');
                    } else {
                      getDailyWorks('Custom Range');
                      bingoGetDailyWorks('Custom Range');
                    }
                  }
                "
              >
                GO
              </v-btn>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date1"
                        label="Date 1"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date1"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" lg="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date2"
                        label="Date 2"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date2"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      loadingCount: 0,
      dateDialog: false,
      jackpotDialog: false,
      kenoJackHistory: [],
      bingoJackHistory: [],

      date1: "",
      date2: "",
      menu1: false,
      menu2: false,

      dailyWork: [],
      todayTotal: 0,
      todayOnhand: 0,
      todayTotalPaid: 0,
      yesterdayTotal: 0,
      yesterdayEarning: 0,
      yesterdayTotalPaid: 0,
      choosedDateRange: "",
      partnerBalance: "",
      partnerCreditBalance: 0,
      depositDialog: false,

      todaysWork: [],
      noBets: 0,
      noPiadTickets: 0,
      cancelledTickets: 0,

      winnersMoney: 0,
      winnersNoTickets: 0,
      winnersNoBets: 0,
      odds: [],
      attemptReport: [],
      attemptReportDialog: false,
      logoutReport: [],
      logoutReportDialog: false,

      winnerMoneyFrequency: [],
      bingoWinnerMoneyFrequency: [],
      inputMoneyFrequency: [],
      bingoInputMoneyFrequency: [],
      inputAndWinnersFrequencyPopUp: false,

      group: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],

      dailyReport: [],
      choosenDate: "Last 15 days",

      items: [
        "Today",
        "Yesterday",
        "Last 15 days",
        "Last 7 days",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],

      bingoDailyWork: [],
      bingoTodayTotal: 0,
      bingoTodayOnhand: 0,
      bingoTodayTotalPaid: 0,
      bingoChoosedDateRange: "",

      bingoTodaysWork: [],
      bingoNoBets: 0,
      bingoNoPiadTickets: 0,
      bingoCancelledTickets: 0,
      bingoWinnersMoney: 0,
      bingoWinnersNoTickets: 0,
      bingoWinnersNoBets: 0,
      bingoOdds: [],
      bingoGroup: null,
      bingoDailyReport: [],
      bingoChoosenDate: "Last 15 days",

      bingoItems: [
        "Today",
        "Yesterday",
        "Last 15 days",
        "Last 7 days",
        "This Month",
        "Last Month",
        "This Year",
        "Custom Range",
      ],
      paidbingoMoneyFromYesterday: 0,
      paidKenoMoneyFromYesterday: 0,
      allDailyWork: [],
      kenoUnpaidList: [],
      bingoUnpaidList: [],
      unpaidDialog: false,
      gameItems: ["All", "bingo"],
      choosedGame: "bingo",

      clearLoading: false,
    };
  },

  // computed: {
  //   computedDateFormatted() {
  //     return this.formatDateRange(this.date);
  //   },
  // },

  // watch: {
  //   date(val) {
  //     this.dateFormatted = this.formatDateRange(this.date);
  //   },
  // },

  methods: {
    // formatDateRange(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${month}/${day}/${year}`;
    // },
    // parseDate(date) {
    //   if (!date) return null;

    //   const [month, day, year] = date.split("/");
    //   return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    // },
    async clearCashierReport() {
      const clearConfirm = confirm(
        "Are you sure you want to clear all reports?"
      );
      if (clearConfirm) {
        this.clearLoading = true;
        try {
          var clearDataResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                  clearCashierReport{
               statusMessage

               }
              }
              `,
            },
          });

          if (
            clearDataResult.data.data.clearCashierReport[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Report cleared completely!");
            this.logoutReportDialog = false;
            this.getCurrentStastics();
          } else alert("Not cleared try again");
        } catch (err) {
          alert(err);
        }
        this.clearLoading = false;
      }
    },

    async clearLoginReport() {
      const clearConfirm = confirm(
        "Are you sure you want to clear all reports?"
      );
      if (clearConfirm) {
        this.clearLoading = true;
        try {
          var clearDataResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                  clearLoginReport{
               statusMessage

               }
              }
              `,
            },
          });

          if (
            clearDataResult.data.data.clearLoginReport[0].statusMessage.toString() ==
            "true"
          ) {
            alert("Report cleared completely!");
            this.attemptReportDialog = false;
            this.getCurrentStastics();
          } else alert("Not cleared try again");
        } catch (err) {
          alert(err);
        }
        this.clearLoading = false;
      }
    },

    // async getJackHistory() {
    //   try {
    //     var kenoJackResult = await axios({
    //       method: "POST",
    //       url: this.$store.state.adminURL,

    //       data: {
    //         query: `{
    //           getJackpotHistory{
    //             jackpot_history_id
    //             cashier_id
    //             ticket_id
    //             selected_no
    //             amount
    //             jack_date
    //             jack_type
    //             is_paid

    //            getCashier{
    //               cashier_full_name
    //             }

    //          }

    //           }
    //           `,
    //       },
    //     });

    //     this.kenoJackHistory = kenoJackResult.data.data.getJackpotHistory;
    //   } catch (err) {}

    //   try {
    //     var bingoJackResult = await axios({
    //       method: "POST",
    //       url: this.$store.state.bingoAdminURL,

    //       data: {
    //         query: `{
    //           getJackpotHistory{
    //             jackpot_history_id
    //             cashier_id
    //             ticket_id
    //             selected_no
    //             amount
    //             jack_date
    //             jack_type
    //             is_paid

    //            getCashier{
    //               cashier_full_name
    //             }

    //          }

    //           }
    //           `,
    //       },
    //     });

    //     this.bingoJackHistory = bingoJackResult.data.data.getJackpotHistory;
    //   } catch (err) {}
    // },

    async getCurrentStastics() {
      if (this.loadingCount == 0) this.pageLoading = true;
      // try {
      //   var partnerBalanceResult = await axios({
      //     method: "POST",
      //     url: this.$store.state.bingoAdminURL,

      //     data: {
      //       query: `{
      //          getPartnerBalance{
      //           partner_id
      //           partner_full_name
      //           partner_phone
      //           partner_balance
      //           tempo_package
      //           permanent_package
      //           credit_chance
      //           getCreditBalance{
      //             credit_id
      //             partner_id
      //             credit_balance
      //           }

      //           getDeposit{
      //            deposit_id
      //             partner_id
      //             amount
      //             transaction_id
      //             deposit_date
      //             is_confirmed

      //           }
      //         }

      //         }
      //         `,
      //     },
      //   });
      //   this.partnerBalance =
      //     partnerBalanceResult.data.data.getPartnerBalance[0];
      //   this.partnerCreditBalance = 0;

      //   for (let i = 0; i < this.partnerBalance.getCreditBalance.length; i++) {
      //     this.partnerCreditBalance =
      //       this.partnerCreditBalance +
      //       this.partnerBalance.getCreditBalance[i].credit_balance;
      //   }
      // } catch (err) {
      //   alert(err);
      // }
      var date2 = Date.now();
      var date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;
      try {
        var todayResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
               getDailyWorks(date1:"${date1}", date2:"${date2}"){
                         game_id
                         cashier_id
                         game_number
                         drawn_numbers
                         game_date
                         stake
                         selected_cartela
                         winners_money
                         earning

                   }
              }
              `,
          },
        });

        this.bingoTodaysWork = todayResult.data.data.getDailyWorks;
        this.bingoTodayTotal = 0;

        this.bingoWinnersMoney = 0;

        for (let t = 0; t < this.bingoTodaysWork.length; t++) {
          this.bingoTodayTotal =
            this.bingoTodayTotal +
            this.bingoTodaysWork[t].winners_money +
            this.bingoTodaysWork[t].earning;
          this.bingoWinnersMoney =
            this.bingoWinnersMoney + this.bingoTodaysWork[t].winners_money;
        }
      } catch (err) {}

      this.loadingCount++;
      this.pageLoading = false;
    },
    async getDailyWorks(passedDate) {
      var today = new Date();
      this.choosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.choosenDate == "Last 15 days") {
        firstDay = new Date(today.setDate(today.getDate() - 14));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.choosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.choosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.choosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.choosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.choosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.choosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.choosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.choosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.choosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.choosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getDailyWorks(date1:"${date1}", date2:"${date2}"){
                      ticket_id
                      game_id
                      user_id
                      choosen_numbers
                      others
                      ticket_date
                      winner_award
                      is_cancelled
                      is_cashout
                      getGameForShifts{
                       game_id
                       game_number
                       drawn_numbers
                       game_date
                       high_low
                       even_odd
                     }

                   }
              }
              `,
          },
        });
        this.dailyWork = dailyResult.data.data.getDailyWorks;

        var allDays = [];
        this.dailyReport = [];

        if (
          this.choosenDate == "Today" ||
          this.choosenDate == "Yesterday" ||
          this.choosenDate == "Last 15 days"
        ) {
          for (let t = 0; t < this.dailyWork.length; t++) {
            // var day = new Date(Number(this.dailyWork[t].ticket_date)).getDay();
            var day = this.formatDate(
              new Date(Number(this.dailyWork[t].ticket_date))
            );

            if (allDays.indexOf(day) === -1) allDays.push(day);
          }
          for (let d = 0; d < allDays.length; d++) {
            var totalMoneyForEach = 0;

            var totalPaidForEach = 0;
            var noTicket = 0;
            var nobet = 0;
            var noPaidTicket = 0;
            var nocancelledTicket = 0;
            var date = "";
            var winnersNoBets = 0;
            var winnersMoney = 0;
            var winnersNoTickets = 0;

            for (let t = 0; t < this.dailyWork.length; t++) {
              var winnersTicketCounter = 0;
              // var day = new Date(
              //   Number(this.dailyWork[t].ticket_date)
              // ).getDay();

              var day = this.formatDate(
                new Date(Number(this.dailyWork[t].ticket_date))
              );

              if (day == allDays[d]) {
                var month =
                  this.monthNames[
                    new Date(Number(this.dailyWork[t].ticket_date)).getMonth()
                  ];

                var eachSplitedDate = this.formatDate(
                  new Date(Number(this.dailyWork[t].ticket_date))
                ).split("-");
                date =
                  month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                var tickets = this.dailyWork[t].choosen_numbers.split(":");

                var drownNumbers =
                  this.dailyWork[t].getGameForShifts[0].drawn_numbers;
                var evenOdd = this.dailyWork[t].getGameForShifts[0].even_odd;
                var highLow = this.dailyWork[t].getGameForShifts[0].high_low;

                if (this.dailyWork[t].is_cancelled == 0) {
                  noTicket = noTicket + 1;

                  if (tickets[0] != "") {
                    nobet = nobet + tickets.length;
                    for (let j = 0; j < tickets.length; j++) {
                      totalMoneyForEach =
                        totalMoneyForEach +
                        parseFloat(tickets[j].split("*")[3]);

                      var eachBet = tickets[j].split("*")[0].trim();

                      var choosenNumber = tickets[j].split("*")[1].trim();

                      var oddValue = parseFloat(
                        tickets[j].split("*")[2].trim()
                      );

                      var eachAmount = parseFloat(
                        tickets[j].split("*")[3].trim()
                      );
                      if (drownNumbers != "") {
                        if (eachBet == "Win") {
                          if (
                            choosenNumber == drownNumbers.split("")[0].trim()
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        } else if (eachBet == "Place") {
                          if (
                            choosenNumber == drownNumbers.split("")[0].trim() ||
                            choosenNumber == drownNumbers.split("")[1].trim() ||
                            choosenNumber == drownNumbers.split("")[2].trim()
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        } else if (eachBet == "Exacta") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[0].trim() &&
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split("")[1].trim()
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        } else if (eachBet == "Quinella") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[0].trim() ||
                            (choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[1].trim() &&
                              choosenNumber.split("-")[1].trim() ==
                                drownNumbers.split("")[0].trim()) ||
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split("")[1].trim()
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        } else if (eachBet == "Swinger") {
                          var swingerMatch =
                            drownNumbers.split("")[0].trim() +
                            "," +
                            drownNumbers.split("")[1].trim() +
                            "," +
                            drownNumbers.split("")[2].trim();
                          if (
                            swingerMatch.includes(
                              choosenNumber.split("-")[0].trim()
                            ) &&
                            swingerMatch.includes(
                              choosenNumber.split("-")[1].trim()
                            )
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        } else if (eachBet == "Trifecta") {
                          if (
                            choosenNumber.split("-")[0].trim() ==
                              drownNumbers.split("")[0].trim() &&
                            choosenNumber.split("-")[1].trim() ==
                              drownNumbers.split("")[1].trim() &&
                            choosenNumber.split("-")[2].trim() ==
                              drownNumbers.split("")[2].trim()
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        } else if (eachBet == "Trio") {
                          var swingerMatch =
                            drownNumbers.split("")[0].trim() +
                            "," +
                            drownNumbers.split("")[1].trim() +
                            "," +
                            drownNumbers.split("")[2].trim();
                          if (
                            swingerMatch.includes(
                              choosenNumber.split("-")[0].trim()
                            ) &&
                            swingerMatch.includes(
                              choosenNumber.split("-")[1].trim()
                            ) &&
                            swingerMatch.includes(
                              choosenNumber.split("-")[2].trim()
                            )
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        } else if (
                          eachBet == "High" ||
                          eachBet == "Low" ||
                          eachBet == "Even" ||
                          eachBet == "Odd"
                        ) {
                          if (
                            choosenNumber == evenOdd ||
                            choosenNumber == highLow
                          ) {
                            winnersNoBets = winnersNoBets + 1;
                            winnersTicketCounter++;
                            winnersMoney = winnersMoney + oddValue * eachAmount;
                          }
                        }
                      }
                    }
                  }
                } else nocancelledTicket = nocancelledTicket + 1;
                if (winnersTicketCounter != 0) winnersNoTickets++;
                if (this.dailyWork[t].winner_award != 0)
                  noPaidTicket = noPaidTicket + 1;
                totalPaidForEach =
                  totalPaidForEach + this.dailyWork[t].winner_award;
              }
            }

            this.dailyReport.push({
              day: allDays[d],
              date: date,
              noTicket: noTicket,
              winnersNoBets: winnersNoBets,
              winnersNoTickets: winnersNoTickets,

              noPaidTicket: noPaidTicket,
              nocancelledTicket: nocancelledTicket,
              noBet: nobet,

              totalMoney: totalMoneyForEach,
              winnersMoney: winnersMoney,
              paidMoney: totalPaidForEach,
              onhand: totalMoneyForEach - totalPaidForEach,
              totalProfit: totalMoneyForEach - winnersMoney,
            });
          }
        } else {
          var totalMoneyForEach = 0;

          var totalPaidForEach = 0;
          var noTicket = 0;
          var nobet = 0;
          var noPaidTicket = 0;
          var nocancelledTicket = 0;
          var date = "";
          var winnersNoBets = 0;
          var winnersMoney = 0;
          var winnersNoTickets = 0;
          this.dailyReport = [];

          for (let t = 0; t < this.dailyWork.length; t++) {
            var winnersTicketCounter = 0;
            var tickets = this.dailyWork[t].choosen_numbers.split(":");

            var drownNumbers =
              this.dailyWork[t].getGameForShifts[0].drawn_numbers;
            var evenOdd = this.dailyWork[t].getGameForShifts[0].even_odd;
            var highLow = this.dailyWork[t].getGameForShifts[0].high_low;
            if (this.dailyWork[t].is_cancelled == 0) {
              noTicket = noTicket + 1;

              if (tickets[0] != "") {
                nobet = nobet + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyForEach =
                    totalMoneyForEach + parseFloat(tickets[j].split("*")[3]);

                  var eachBet = tickets[j].split("*")[0].trim();

                  var choosenNumber = tickets[j].split("*")[1].trim();

                  var oddValue = parseFloat(tickets[j].split("*")[2].trim());

                  var eachAmount = parseFloat(tickets[j].split("*")[3].trim());
                  if (drownNumbers != "") {
                    if (eachBet == "Win") {
                      if (choosenNumber == drownNumbers.split("")[0].trim()) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Place") {
                      if (
                        choosenNumber == drownNumbers.split("")[0].trim() ||
                        choosenNumber == drownNumbers.split("")[1].trim() ||
                        choosenNumber == drownNumbers.split("")[2].trim()
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Exacta") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[0].trim() &&
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split("")[1].trim()
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Quinella") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[0].trim() ||
                        (choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[1].trim() &&
                          choosenNumber.split("-")[1].trim() ==
                            drownNumbers.split("")[0].trim()) ||
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split("")[1].trim()
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Swinger") {
                      var swingerMatch =
                        drownNumbers.split("")[0].trim() +
                        "," +
                        drownNumbers.split("")[1].trim() +
                        "," +
                        drownNumbers.split("")[2].trim();
                      if (
                        swingerMatch.includes(
                          choosenNumber.split("-")[0].trim()
                        ) &&
                        swingerMatch.includes(
                          choosenNumber.split("-")[1].trim()
                        )
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Trifecta") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[0].trim() &&
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split("")[1].trim() &&
                        choosenNumber.split("-")[2].trim() ==
                          drownNumbers.split("")[2].trim()
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Trio") {
                      var swingerMatch =
                        drownNumbers.split("")[0].trim() +
                        "," +
                        drownNumbers.split("")[1].trim() +
                        "," +
                        drownNumbers.split("")[2].trim();
                      if (
                        swingerMatch.includes(
                          choosenNumber.split("-")[0].trim()
                        ) &&
                        swingerMatch.includes(
                          choosenNumber.split("-")[1].trim()
                        ) &&
                        swingerMatch.includes(
                          choosenNumber.split("-")[2].trim()
                        )
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (
                      eachBet == "High" ||
                      eachBet == "Low" ||
                      eachBet == "Even" ||
                      eachBet == "Odd"
                    ) {
                      if (
                        choosenNumber == evenOdd ||
                        choosenNumber == highLow
                      ) {
                        winnersNoBets = winnersNoBets + 1;
                        winnersTicketCounter++;
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    }
                  }
                }
              }
            } else nocancelledTicket = nocancelledTicket + 1;
            if (winnersTicketCounter != 0) winnersNoTickets++;
            if (this.dailyWork[t].winner_award != 0)
              noPaidTicket = noPaidTicket + 1;
            totalPaidForEach =
              totalPaidForEach + this.dailyWork[t].winner_award;
          }

          this.dailyReport.push({
            day: "",
            date: "",
            noTicket: noTicket,
            winnersNoTickets: winnersNoTickets,
            noBet: nobet,
            winnersNoBets: winnersNoBets,
            noPaidTicket: noPaidTicket,
            nocancelledTicket: nocancelledTicket,

            totalMoney: totalMoneyForEach,
            winnersMoney: winnersMoney,
            paidMoney: totalPaidForEach,
            onhand: totalMoneyForEach - totalPaidForEach,
            totalProfit: totalMoneyForEach - winnersMoney,
          });
        }

        this.allDailyWork = [];

        for (let a = 0; a < this.dailyReport.length; a++) {
          let existCount = 0;
          for (let s = 0; s < this.bingoDailyReport.length; s++) {
            if (
              this.dailyReport[a].day == this.bingoDailyReport[s].day &&
              this.dailyReport[a].date == this.bingoDailyReport[s].date
            ) {
              existCount++;

              this.allDailyWork.push({
                day: this.dailyReport[a].day,
                date: this.dailyReport[a].date,
                noTicket:
                  this.dailyReport[a].noTicket +
                  this.bingoDailyReport[s].noTicket,
                winnersNoTickets:
                  this.dailyReport[a].winnersNoTickets +
                  this.bingoDailyReport[s].winnersNoTickets,
                noBet:
                  this.dailyReport[a].noBet + this.bingoDailyReport[s].noBet,
                winnersNoBets:
                  this.dailyReport[a].winnersNoBets +
                  this.bingoDailyReport[s].winnersNoBets,
                noPaidTicket:
                  this.dailyReport[a].noPaidTicket +
                  this.bingoDailyReport[s].noPaidTicket,
                nocancelledTicket:
                  this.dailyReport[a].nocancelledTicket +
                  this.bingoDailyReport[s].nocancelledTicket,

                totalMoney:
                  this.dailyReport[a].totalMoney +
                  this.bingoDailyReport[s].totalMoney,
                winnersMoney:
                  this.dailyReport[a].winnersMoney +
                  this.bingoDailyReport[s].winnersMoney,
                paidMoney:
                  this.dailyReport[a].paidMoney +
                  this.bingoDailyReport[s].paidMoney,
                onhand:
                  this.dailyReport[a].onhand + this.bingoDailyReport[s].onhand,

                totalProfit:
                  this.dailyReport[a].totalProfit +
                  this.bingoDailyReport[s].totalProfit,
              });
            }
          }

          if (existCount == 0) {
            this.allDailyWork.push({
              day: this.dailyReport[a].day,
              date: this.dailyReport[a].date,
              noTicket: this.dailyReport[a].noTicket,
              winnersNoTickets: this.dailyReport[a].winnersNoTickets,
              noBet: this.dailyReport[a].noBet,
              winnersNoBets: this.dailyReport[a].winnersNoBets,
              noPaidTicket: this.dailyReport[a].noPaidTicket,
              nocancelledTicket: this.dailyReport[a].nocancelledTicket,

              totalMoney: this.dailyReport[a].totalMoney,
              winnersMoney: this.dailyReport[a].winnersMoney,
              paidMoney: this.dailyReport[a].paidMoney,
              onhand: this.dailyReport[a].onhand,
              totalProfit: this.dailyReport[a].totalProfit,
            });
          }
        }

        for (let a = 0; a < this.bingoDailyReport.length; a++) {
          let existCount = 0;
          for (let s = 0; s < this.dailyReport.length; s++) {
            if (
              this.dailyReport[s].day == this.bingoDailyReport[a].day &&
              this.dailyReport[s].date == this.bingoDailyReport[a].date
            ) {
              existCount++;
            }
          }

          if (existCount == 0) {
            this.allDailyWork.push({
              day: this.bingoDailyReport[a].day,
              date: this.bingoDailyReport[a].date,
              noTicket: this.bingoDailyReport[a].noTicket,
              winnersNoTickets: this.bingoDailyReport[a].winnersNoTickets,
              noBet: this.bingoDailyReport[a].noBet,
              winnersNoBets: this.bingoDailyReport[a].winnersNoBets,
              noPaidTicket: this.bingoDailyReport[a].noPaidTicket,
              nocancelledTicket: this.bingoDailyReport[a].nocancelledTicket,

              totalMoney: this.bingoDailyReport[a].totalMoney,
              winnersMoney: this.bingoDailyReport[a].winnersMoney,
              paidMoney: this.bingoDailyReport[a].paidMoney,
              onhand: this.bingoDailyReport[a].onhand,
              totalProfit: this.bingoDailyReport[a].totalProfit,
            });
          }
        }

        this.allDailyWork.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        // this.yesterdayEarning = this.yesterdayTotal - this.yesterdayTotalPaid;
        // this.todayEarning = this.todayTotal - this.todayTotalPaid;
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    async bingoGetDailyWorks(passedDate) {
      var today = new Date();
      this.bingoChoosenDate = passedDate;

      var firstDay = "";
      var date2 = "";
      var date1 = "";
      if (this.bingoChoosenDate == "Last 15 days") {
        firstDay = new Date(today.setDate(today.getDate() - 14));
        date2 = Date.now();

        this.bingoChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.bingoChoosenDate == "Today") {
        firstDay = new Date(today.setDate(today.getDate() - 0));
        date2 = Date.now();
        date1 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.bingoChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
      } else if (this.bingoChoosenDate == "Yesterday") {
        firstDay = new Date(today.setDate(today.getDate() - 1));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
        date2 =
          new Date(this.formatDate(new Date(Date.now()))).getTime() - 9688995;

        this.bingoChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(firstDay.getTime()));
      } else if (this.bingoChoosenDate == "Last 7 days") {
        firstDay = new Date(today.setDate(today.getDate() - 6));
        date2 = Date.now();

        this.bingoChoosedDateRange =
          this.formatDate(new Date(firstDay.getTime())) +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(firstDay.getTime()))
        ).getTime();
      } else if (this.bingoChoosenDate == "This Year") {
        firstDay = new Date(today.setDate(today.getDate() - 29));
        date2 = Date.now();

        this.bingoChoosedDateRange =
          this.formatDate(new Date(date2)).split("-")[0] +
          "-01-01" +
          " - " +
          this.formatDate(new Date(date2));
        date1 = new Date(
          this.formatDate(new Date(date2)).split("-")[0] + "-01-01"
        ).getTime();
      } else if (this.bingoChoosenDate == "This Month") {
        firstDay = this.formatDate(new Date(Date.now())).substring(0, 8) + "01";

        date2 = Date.now();

        this.bingoChoosedDateRange =
          firstDay + " - " + this.formatDate(new Date(date2));
        date1 = new Date(firstDay).getTime();
      } else if (this.bingoChoosenDate == "Last Month") {
        firstDay = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        ).getTime();

        date2 = new Date(today.getFullYear(), today.getMonth(), 0).getTime();

        this.bingoChoosedDateRange =
          this.formatDate(firstDay) + " - " + this.formatDate(new Date(date2));
        date1 = firstDay;
      } else if (this.bingoChoosenDate == "Custom Range") {
        date2 = new Date(this.date2).getTime();
        this.bingoChoosedDateRange = this.date1 + " - " + this.date2;
        date1 = new Date(this.date1).getTime();
      }

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var dailyResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
               getDailyWorks(date1:"${date1}", date2:"${date2}"){
                        game_id
                         cashier_id
                         game_number
                         drawn_numbers
                         game_date
                         stake
                         selected_cartela
                         winners_money
                         earning

                   }
              }
              `,
          },
        });
        this.bingoDailyWork = dailyResult.data.data.getDailyWorks;

        var allDays = [];
        this.bingoDailyReport = [];

        if (
          this.bingoChoosenDate == "Today" ||
          this.bingoChoosenDate == "Yesterday" ||
          this.bingoChoosenDate == "Last 15 days"
        ) {
          for (let t = 0; t < this.bingoDailyWork.length; t++) {
            // var day = new Date(Number(this.dailyWork[t].ticket_date)).getDay();
            var day = this.formatDate(
              new Date(Number(this.bingoDailyWork[t].game_date))
            );

            if (allDays.indexOf(day) === -1) allDays.push(day);
          }
          for (let d = 0; d < allDays.length; d++) {
            var totalMoneyForEach = 0;

            var noTicket = 0;

            var date = "";

            var winnersMoney = 0;

            for (let t = 0; t < this.bingoDailyWork.length; t++) {
              var day = this.formatDate(
                new Date(Number(this.bingoDailyWork[t].game_date))
              );

              if (day == allDays[d]) {
                var month =
                  this.monthNames[
                    new Date(
                      Number(this.bingoDailyWork[t].game_date)
                    ).getMonth()
                  ];

                var eachSplitedDate = this.formatDate(
                  new Date(Number(this.bingoDailyWork[t].game_date))
                ).split("-");
                date =
                  month + " " + eachSplitedDate[2] + "/" + eachSplitedDate[0];

                noTicket = noTicket + 1;

                totalMoneyForEach =
                  totalMoneyForEach +
                  this.bingoDailyWork[t].winners_money +
                  this.bingoDailyWork[t].earning;
                winnersMoney =
                  winnersMoney + this.bingoDailyWork[t].winners_money;
              }
            }

            this.bingoDailyReport.push({
              day: allDays[d],
              date: date,
              noTicket: noTicket,
              totalMoney: totalMoneyForEach,
              winnersMoney: winnersMoney,

              totalProfit: totalMoneyForEach - winnersMoney,
            });
          }
        } else {
          var totalMoneyForEach = 0;

          var noTicket = 0;

          var date = "";

          var winnersMoney = 0;

          for (let t = 0; t < this.bingoDailyWork.length; t++) {
            noTicket = noTicket + 1;

            totalMoneyForEach =
              totalMoneyForEach +
              this.bingoDailyWork[t].winners_money +
              this.bingoDailyWork[t].earning;
            winnersMoney = winnersMoney + this.bingoDailyWork[t].winners_money;
          }

          this.bingoDailyReport.push({
            day: "",
            date: "",
            noTicket: noTicket,

            totalMoney: totalMoneyForEach,
            winnersMoney: winnersMoney,

            totalProfit: totalMoneyForEach - winnersMoney,
          });
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    async confirmDeposit(depositItem) {
      const depositConfirm = confirm("Are you sure you want to confirm?");

      if (depositConfirm) {
        try {
          var confirmdepositResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,

            data: {
              query: `mutation{
                        confirmDeposit(
                          partner_id:${this.partnerBalance.partner_id},
                          deposit_id:${depositItem.deposit_id},
                          amount:${depositItem.amount},
                        loan_amount: ${this.partnerCreditBalance},
                        oldAmount:${this.partnerBalance.partner_balance}){
                     statusMessage
                    }

              }
              `,
            },
          });

          var result =
            confirmdepositResult.data.data.confirmDeposit[0].statusMessage;
          if (result.toString() == "true") {
            alert("Confirmed successfully!");

            this.depositDialog = false;
            this.getCurrentStastics();
          } else if (result.toString() == "false") {
            alert(
              "Some thing went wrong please contact the owner of this system"
            );
          } else alert(result);
        } catch (err) {
          alert(err);
        }
      }
    },

    async rejectDeposit(depositItem) {
      const rejectConfirm = confirm(
        "Are you sure you want to reject this deposit?"
      );

      if (rejectConfirm) {
        try {
          var rejectdepositResult = await axios({
            method: "POST",
            url: this.$store.state.adminURL,
            data: {
              query: `mutation{
                       rejectDeposit(
                          deposit_id:${depositItem.deposit_id},
                         ){
                     statusMessage
                    }

              }
              `,
            },
          });

          var result =
            rejectdepositResult.data.data.rejectDeposit[0].statusMessage;
          if (result.toString() == "true") {
            alert("Rejected successfully!");
            this.depositDialog = false;
            this.getCurrentStastics();
          } else if (result.toString() == "false") {
            alert(
              "Some thing went wrong please contact the owner of this system"
            );
          } else alert(result);
        } catch (err) {
          alert(err);
        }
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    // alert(window.location.protocol + "//" + window.location.host);
    if (this.$store.state.admin != "") {
      await this.getCurrentStastics();
      // await this.getDailyWorks("Last 15 days");
      await this.bingoGetDailyWorks("Last 15 days");

      // await this.getJackHistory();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 30px;
}

#chooseDate {
  max-width: 400px;

  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
