<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Packages and Loans</p>
      <div v-if="$store.state.admin != ''" class="" style="font-size: 13px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pa-10 pl-1 pr-1 pl-5"
          id="report"
          style="border-radius: 3px"
        >
          <div class="" id="creditChance">
            <v-layout
              row
              wrap
              v-for="(history, i) in boughtPackages"
              :key="history.bought_packages_id"
            >
              <v-flex xs12 class="text-center" v-if="i == 0">
                <span class="text-center">Bought Packages</span>
              </v-flex>
              <v-flex xs12 class="text-center">
                <span
                  >{{ history.package_with_price.split(",")[0] }} Birr
                  &nbsp;&nbsp;
                  {{ history.package_with_price.split(",")[1] }} Packages</span
                >
              </v-flex>
            </v-layout>
            <div class="mt-10">
              <h3 class="grey--text text--darken-1 ml-10">
                Change Credit chance
              </h3>
            </div>
            <v-layout row wrap class="pl-3">
              <v-flex xs8 class="pr-2">
                <v-text-field
                  class="mt-5"
                  hide-details="true"
                  v-model="partnerCreditChance"
                  :rules="[(v) => !!v || 'Required']"
                  placeholder="Credit chance"
                  required
                  dense
                  outlined
                ></v-text-field>
              </v-flex>

              <v-flex xs2 md1 class="ma-1 pt-4 mt-2">
                <v-btn
                  small
                  text
                  class="primary"
                  type="submit"
                  @click="changeCreditChance"
                  :loading="changeCreditLoading"
                >
                  <span class="text-capitalize">Go</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
          <div class="mt-10" id="package">
            <h3 class="grey--text text--darken-1 ml-10">
              Packages and thier prices
            </h3>
          </div>

          <div id="package">
            <v-layout
              row
              wrap
              class="pt-3 ml-3"
              v-for="(packages, i) in packageList"
              :key="packages.package_id"
            >
              <v-flex xs4 v-if="i == 0" class="mb-5">
                <h3 class="ml-6">Price</h3>
              </v-flex>
              <v-flex xs3 v-if="i == 0" class="mb-5">
                <h3 class="">Package</h3>
              </v-flex>
              <v-flex xs3 v-if="i == 0"> </v-flex>
              <v-flex xs4 class="mt-2">
                <span>{{ i + 1 }}. &nbsp;&nbsp; {{ packages.price }}</span>
              </v-flex>

              <v-flex xs3 class="mb-5 mt-2">
                <span> &nbsp;&nbsp; {{ packages.package_amount }}</span>
              </v-flex>

              <v-flex xs3>
                <v-btn
                  text
                  small
                  @click="
                    selectedPackageInfo = packages;
                    packagePrice = packages.price;
                    changePackagePopup = true;
                  "
                >
                  <span class="text-capitalize blue--text"
                    ><v-icon>edit</v-icon></span
                  >
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
        </div>

        <v-dialog v-model="changePackagePopup" persistent max-width="420">
          <v-card class="text-center">
            <v-card-title> Update package price </v-card-title>

            <v-form @submit.prevent="" class="pl-4 pr-4">
              <v-card-text>
                <v-text-field
                  hide-details="true"
                  v-model="packagePrice"
                  :rules="[(v) => !!v || 'Required']"
                  label="Enter price"
                  required
                  dense
                  outlined
                  prepend-icon="attach_money"
                ></v-text-field>
              </v-card-text>
              <span class="text-center">
                {{ packagePrice }} Birr For
                {{ selectedPackageInfo.package_amount }}
              </span>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :loading="changePriceLoading"
                  color="green darken-1"
                  class="text-capitalize"
                  text
                  type="submit"
                  @click="changePackagePrice"
                >
                  Save
                </v-btn>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="changePackagePopup = false"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pageLoading: false,
      changePackagePopup: false,
      changePriceLoading: false,
      changeCreditLoading: false,
      packagePrice: "",
      selectedPackageInfo: "",
      partnerBalance: "",
      partnerCreditChance: "",
      packageList: [],
      loanList: [],
      boughtPackages: [],
    };
  },

  methods: {
    async getPackages() {
      this.pageLoading = true;
      try {
        var packageResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
               getPackages{
                 package_id
                 price
                 package_amount
               }


              }
              `,
          },
        });
        this.packageList = packageResult.data.data.getPackages;
      } catch (err) {
        alert(err);
      }

      try {
        var partnerBalanceResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
                getPartnerBalance{
                     tempo_package
                     permanent_package
                     partner_balance
                     credit_chance
                     getCreditBalance{
                       credit_balance
                       credit_id
                     }
    
                   }
               
              }
              `,
          },
        });
        this.partnerBalance =
          partnerBalanceResult.data.data.getPartnerBalance[0];
        this.partnerCreditChance = this.partnerBalance.credit_chance;
      } catch (err) {}

      try {
        var loanResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
              getLoans{
                loan_tbl_id
                loan_amount
              }


              }
              `,
          },
        });
        this.loanList = loanResult.data.data.getLoans;
      } catch (err) {}
      try {
        var boughtPackagesResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
              getBoughtPackages {
                bought_packages_id
                package_with_price
                bought_packages_date
               }
              }
              `,
          },
        });
        this.boughtPackages = boughtPackagesResult.data.data.getBoughtPackages;
      } catch (err) {}

      this.pageLoading = false;
    },

    async changePackagePrice() {
      if (this.packagePrice != "") {
        this.changePriceLoading = true;
        try {
          var packageResult = await axios({
            method: "POST",
            url: this.$store.state.bingoAdminURL,

            data: {
              query: `mutation{
               updatePackage(package_id:${this.selectedPackageInfo.package_id},price:${this.packagePrice} ){
                statusMessage
               }


              }
              `,
            },
          });

          var result = packageResult.data.data.updatePackage[0].statusMessage;
          if (result.toString() == "true") {
            alert("Updated successfully!");

            this.changePackagePopup = false;
            await this.getPackages();
          } else if (result.toString() == "false") {
            alert("Some thing went wrong, please try again");
          } else alert(result);
        } catch (err) {
          alert(err);
        }
        this.changePriceLoading = false;
      } else alert("The price cannot be empty");
    },

    async changeCreditChance() {
      if (this.partnerCreditChance != "") {
        this.changeCreditLoading = true;
        try {
          var creditResult = await axios({
            method: "POST",
            url: this.$store.state.bingoAdminURL,

            data: {
              query: `mutation{
               updateCreditChance(credit_chance:${this.partnerCreditChance} ){
                statusMessage
               }


              }
              `,
            },
          });

          var result =
            creditResult.data.data.updateCreditChance[0].statusMessage;
          if (result.toString() == "true") {
            alert("Updated successfully!");

            await this.getPackages();
          } else if (result.toString() == "false") {
            alert("Some thing went wrong, please try again");
          } else alert(result);
        } catch (err) {
          alert(err);
        }
        this.changeCreditLoading = false;
      } else alert("The credit chance cannot be empty");
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      await this.getPackages();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#package {
  max-width: 500px;
  margin: auto;
  margin-top: 10px;
}

#creditChance {
  max-width: 500px;
  margin: auto;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
