<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Reported Tickets</p>
      <div v-if="$store.state.admin != ''" class="" style="font-size: 13px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-if="$store.state.whichToShow == 'Dogs'">
          <div
            v-if="!pageLoading"
            class="white pa-10 pl-1 pr-1"
            id="report"
            style="border-radius: 3px"
          >
            <v-layout row wrap justify-space-around="">
              <v-flex xs12 md5>
                <div id="chooseDate" class="ml-10 mr-10 pl-10">
                  <h3 class="mt-6 grey--text text--darken-1 ml-10">
                    Dogs Reported Tickets
                  </h3>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              id="chooseDate"
              row
              wrap
              class="mb-15 pa-3 mt-6"
              style="font-size: 14px"
            >
              <v-flex
                xs12
                v-for="(reportedTicket, i) in reportedTickets"
                :key="reportedTicket.reported_ticket_id"
              >
                <v-divider v-if="i != 0" class="ma-4 ml-0 mr-0"></v-divider>

                <h3>Ticket Info:</h3>
                <span
                  >User ID:
                  <strong>{{
                    reportedTicket.getTicket[0].user_id
                  }}</strong></span
                >
                <br />
                <span v-if="reportedTicket.getTicket[0].getGame.length > 0"
                  >Game Number:
                  <strong
                    v-if="
                      reportedTicket.getTicket[0].getGame[0].game_number != ''
                    "
                    >{{
                      reportedTicket.getTicket[0].getGame[0].game_number
                    }}</strong
                  ></span
                >
                <br />
                <span v-if="reportedTicket.getTicket[0].choosen_numbers != ''"
                  >Choosen numbers:
                  <strong>{{
                    reportedTicket.getTicket[0].choosen_numbers
                  }}</strong></span
                >

                <span v-if="reportedTicket.getTicket[0].others != ''">
                  <br />
                  Others:
                  <strong>{{
                    reportedTicket.getTicket[0].others
                  }}</strong></span
                >
                <br />
                <span
                  >Ticket Date:
                  <strong>{{
                    new Date(Number(reportedTicket.getTicket[0].ticket_date))
                      | formatDate
                  }}</strong></span
                >
                <br />
                <span
                  >Why reported:
                  <strong>{{ reportedTicket.reason }}</strong></span
                >
                <br /><br />
                <h3>Game Info:</h3>
                <span
                  >Drawn Numbers:
                  <strong
                    v-if="reportedTicket.getTicket[0].getGame.length > 0"
                    >{{
                      reportedTicket.getTicket[0].getGame[0].drawn_numbers
                    }}</strong
                  ></span
                >
                <br />
                <span
                  >Even/Odd:
                  <strong
                    v-if="reportedTicket.getTicket[0].getGame.length > 0"
                    >{{
                      reportedTicket.getTicket[0].getGame[0].even_odd
                    }}</strong
                  ></span
                >
                <br />
                <span
                  >High/Low:
                  <strong
                    v-if="reportedTicket.getTicket[0].getGame.length > 0"
                    >{{
                      reportedTicket.getTicket[0].getGame[0].high_low
                    }}</strong
                  ></span
                >
                <br />
                <span
                  >Cashier Name:
                  <strong>{{
                    reportedTicket.getTicket[0].getCashier[0].cashier_full_name
                  }}</strong></span
                >
              </v-flex>
            </v-layout>
          </div>
        </div>

        <div v-if="$store.state.whichToShow == 'bingo'">
          <div
            v-if="!pageLoading"
            class="white pa-10 pl-1 pr-1"
            id="report"
            style="border-radius: 3px"
          >
            <v-layout row wrap justify-space-around="">
              <v-flex xs12 md5>
                <div id="chooseDate" class="ml-10 mr-10 pl-10">
                  <h3 class="mt-6 grey--text text--darken-1 ml-10">
                    bingo Reported Tickets
                  </h3>
                </div>
              </v-flex>
            </v-layout>
            <v-layout
              id="chooseDate"
              row
              wrap
              class="mb-15 pa-3 mt-6"
              style="font-size: 14px"
            >
              <v-flex
                xs12
                v-for="(reportedTicket, i) in bingoReportedTickets"
                :key="reportedTicket.reported_ticket_id"
              >
                <v-divider v-if="i != 0" class="ma-4 ml-0 mr-0"></v-divider>

                <h3>Ticket Info:</h3>
                <span
                  >User ID:
                  <strong>{{
                    reportedTicket.getTicket[0].user_id
                  }}</strong></span
                >
                <br />
                <span v-if="reportedTicket.getTicket[0].getGame.length > 0"
                  >Game Number:
                  <strong
                    v-if="
                      reportedTicket.getTicket[0].getGame[0].game_number != ''
                    "
                    >{{
                      reportedTicket.getTicket[0].getGame[0].game_number
                    }}</strong
                  ></span
                >
                <br />
                <span v-if="reportedTicket.getTicket[0].choosen_numbers != ''"
                  >Choosen numbers:
                  <strong>{{
                    reportedTicket.getTicket[0].choosen_numbers
                  }}</strong></span
                >

                <br />
                <span
                  >Ticket Date:
                  <strong>{{
                    new Date(Number(reportedTicket.getTicket[0].ticket_date))
                      | formatDate
                  }}</strong></span
                >
                <br />
                <span
                  >Why reported:
                  <strong>{{ reportedTicket.reason }}</strong></span
                >
                <br /><br />
                <h3>Game Info:</h3>
                <span
                  >Drawn Numbers:
                  <strong
                    v-if="reportedTicket.getTicket[0].getGame.length > 0"
                    >{{
                      reportedTicket.getTicket[0].getGame[0].drawn_numbers
                    }}</strong
                  ></span
                >
                <br />
                <span
                  >Others:
                  <strong
                    v-if="reportedTicket.getTicket[0].getGame.length > 0"
                    >{{
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .number +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .color +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .mirror +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .twins +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .final +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .dozen +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .sector +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .combination +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .even_odd +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .high_low +
                      ", " +
                      reportedTicket.getTicket[0].getGame[0].getbingoNumber[0]
                        .neighbors
                    }}</strong
                  ></span
                >
                <br />

                <span
                  >Cashier Name:
                  <strong>{{
                    reportedTicket.getTicket[0].getCashier[0].cashier_full_name
                  }}</strong></span
                >
              </v-flex>
            </v-layout>
          </div>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">Please login first</p>
        <a @click="$router.push({ name: 'login' })">login</a>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      pageLoading: false,
      reportedTickets: [],
      bingoReportedTickets: [],
    };
  },

  methods: {
    async getReportedTickets() {
      this.pageLoading = true;
      try {
        var reportedTicketsResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                getReportedTickets {
                     reported_ticket_id
                     user_id
                     reason
                     getTicket {
                       game_id
                       user_id
                       ticket_id
                       choosen_numbers
                       others
                       ticket_date
                       winner_award
                       is_cancelled
                        getCashier {
                           cashier_full_name
                         }
                       getGame {
                         game_number
                         drawn_numbers
                         high_low
                         even_odd
                        
                       }
                     }
  }                 
              
              }
              `,
          },
        });
        this.reportedTickets =
          reportedTicketsResult.data.data.getReportedTickets;
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
    async bingoGetReportedTickets() {
      this.pageLoading = true;
      try {
        var reportedTicketsResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
                getReportedTickets {
                     reported_ticket_id
                     user_id
                     reason
                     getTicket {
                       game_id
                       user_id
                       ticket_id
                       choosen_numbers
                       others
                       ticket_date
                       winner_award
                       is_cancelled
                        getCashier {
                           cashier_full_name
                         }
                       getGame {
                         game_number
                         drawn_numbers
                         high_low
                         even_odd
                        
                        
                       }
                     }
  }                 
              
              }
              `,
          },
        });
        this.bingoReportedTickets =
          reportedTicketsResult.data.data.getReportedTickets;
      } catch (err) {
        alert(err);
      }

      this.pageLoading = false;
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      await this.getReportedTickets();
      await this.bingoGetReportedTickets();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
