<template>
  <div>
    <v-container>
      <p class="headline white--text ml-5">Game Analysis</p>
      <div v-if="$store.state.admin != ''" class="" style="font-size: 13px">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>

        <div
          v-if="!pageLoading"
          class="white pa-10 pl-1 pr-1"
          id="report"
          style="border-radius: 3px"
        >
          <div v-if="$store.state.whichToShow == 'Dogs'">
            <v-layout row wrap justify-space-around="">
              <v-flex xs12 md5>
                <div id="chooseDate" class="ml-10 mr-10 pl-10">
                  <h3 class="mt-6 grey--text text--darken-1 ml-10">
                    Today's Dogs Game Analysis
                  </h3>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="mb-15 pa-3 pt-0" style="font-size: 14px">
              <horizontal-scroll
                class="horizontal-scroll white pa-3 pl-0 pt-0"
                id="report"
              >
                <table style="width: 100%" class="ma-3 mt-2">
                  <tr>
                    <th>Date</th>
                    <th>Last T Date</th>
                    <th>Game#</th>
                    <th>No Tickets</th>
                    <th>No Paid Tickets</th>
                    <th>No Cancelled Tickets</th>
                    <th>No Bets</th>

                    <th>Total Money</th>
                    <th>Winner Money</th>
                    <th>Paid Money</th>
                    <th>Profit</th>
                    <th>Percent(%)</th>
                  </tr>

                  <tr v-for="(game, i) in dailyReport" :key="i">
                    <td>
                      <span>
                        {{ new Date(Number(game.date)) | formatDate }}
                      </span>
                    </td>
                    <td>
                      <span v-if="game.lastTicket != ''">
                        {{ new Date(Number(game.lastTicket)) | formatDate }}
                      </span>
                    </td>

                    <td>
                      <span>
                        {{ game.gameNo }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ game.totalTickets }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ game.totalPaidTickets }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ game.cancelledTickets }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ game.noBets }}
                      </span>
                    </td>

                    <td>
                      <span>
                        {{ game.totalMoney.toFixed(2) }}
                        <span style="font-size: 11px">ETB</span>
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ game.winnerMoney.toFixed(2) }}
                        <span style="font-size: 11px">ETB</span>
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ game.paidMoney.toFixed(2) }}
                        <span style="font-size: 11px">ETB</span>
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ game.totalEarning.toFixed(2) }}
                        <span style="font-size: 11px">ETB</span>
                      </span>
                    </td>
                    <td>
                      <span> {{ game.percent }}% </span>
                    </td>
                  </tr>
                </table>
              </horizontal-scroll>
            </v-layout>
          </div>
          <div v-if="$store.state.whichToShow == 'bingo'">
            <div
              v-if="!pageLoading"
              class="white pa-10 pl-1 pr-1"
              id="report"
              style="border-radius: 3px"
            >
              <v-layout row wrap justify-space-around="">
                <v-flex xs12 md5>
                  <div id="chooseDate" class="ml-10 mr-10 pl-10">
                    <h3 class="mt-6 grey--text text--darken-1 ml-10">
                      Today's bingo Game Analysis
                    </h3>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                class="mb-15 pa-3 pt-0"
                style="font-size: 14px"
              >
                <horizontal-scroll
                  class="horizontal-scroll white pa-3 pl-0 pt-0"
                  id="report"
                >
                  <table style="width: 100%" class="ma-3 mt-2">
                    <tr>
                      <th>Date</th>
                      <th>Last T Date</th>
                      <th>Game#</th>
                      <th>No Tickets</th>
                      <th>No Paid Tickets</th>
                      <th>No Cancelled Tickets</th>
                      <th>No Bets</th>

                      <th>Total Money</th>
                      <th>Winner Money</th>
                      <th>Paid Money</th>
                      <th>Profit</th>
                      <th>Percent(%)</th>
                    </tr>

                    <tr v-for="(game, i) in bingoDailyReport" :key="i">
                      <td>
                        <span>
                          {{ new Date(Number(game.date)) | formatDate }}
                        </span>
                      </td>
                      <td>
                        <span v-if="game.lastTicket != ''">
                          {{ new Date(Number(game.lastTicket)) | formatDate }}
                        </span>
                      </td>

                      <td>
                        <span>
                          {{ game.gameNo }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.totalTickets }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.totalPaidTickets }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.cancelledTickets }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.noBets }}
                        </span>
                      </td>

                      <td>
                        <span>
                          {{ game.totalMoney.toFixed(2) }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.winnerMoney.toFixed(2) }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.paidMoney.toFixed(2) }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span>
                          {{ game.totalEarning.toFixed(2) }}
                          <span style="font-size: 11px">ETB</span>
                        </span>
                      </td>
                      <td>
                        <span> {{ game.percent }}% </span>
                      </td>
                    </tr>
                  </table>
                </horizontal-scroll>
              </v-layout>
            </div>
          </div>
        </div>
      </div>

      <div v-else id="error" class="text-center mt-16">
        <p class="red--text text-center">
          You are blocked, please contact the admin
        </p>
        <!-- <a @click="$router.push({ name: 'login' })">login</a> -->
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      pageLoading: false,
      loadingCount: 0,

      dailyWork: [],
      odds: [],
      dailyReport: [],

      bingoDailyWork: [],
      bingoOdds: [],
      bingoDailyReport: [],
    };
  },

  methods: {
    async getTodaysGameAnalysis() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() -
        (24 * 60 * 60 * 1000 + 8683989);

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var gameAnalysisResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
               getTodaysGameAnalysis(date1:"${date1}", date2:"${date2}"){
                   game_id                 
                   game_number
                   drawn_numbers
                   game_date
                   high_low
                   even_odd
                   game_status
                   percent
                   getTickets {
                     ticket_id
                     game_id
                     user_id
                     choosen_numbers
                     others
                     ticket_date
                     winner_award
                     is_cancelled
                   }
                    
              }
              
              }
              `,
          },
        });
        this.dailyWork = gameAnalysisResult.data.data.getTodaysGameAnalysis;

        this.dailyReport = [];

        for (let i = 0; i < this.dailyWork.length; i++) {
          var totalMoneyOnHand = 0;
          var totalMoneyPaid = 0;
          var totalTickets = 0;
          var totalPaidTickets = 0;
          var noBets = 0;
          var winnersMoney = 0;

          for (let t = 0; t < this.dailyWork[i].getTickets.length; t++) {
            var tickets =
              this.dailyWork[i].getTickets[t].choosen_numbers.split(":");

            var drownNumbers = this.dailyWork[i].drawn_numbers;

            var evenOdd = this.dailyWork[i].even_odd;
            var highLow = this.dailyWork[i].high_low;
            if (this.dailyWork[i].getTickets[t].is_cancelled == 0) {
              if (tickets[0] != "") {
                noBets = noBets + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyOnHand =
                    totalMoneyOnHand + parseFloat(tickets[j].split("*")[3]);
                  var eachBet = tickets[j].split("*")[0].trim();

                  var choosenNumber = tickets[j].split("*")[1].trim();

                  var oddValue = parseFloat(tickets[j].split("*")[2].trim());

                  var eachAmount = parseFloat(tickets[j].split("*")[3].trim());

                  if (drownNumbers != "") {
                    if (eachBet == "Win") {
                      if (choosenNumber == drownNumbers.split("")[0].trim()) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Place") {
                      if (
                        choosenNumber == drownNumbers.split("")[0].trim() ||
                        choosenNumber == drownNumbers.split("")[1].trim() ||
                        choosenNumber == drownNumbers.split("")[2].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Exacta") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[0].trim() &&
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split("")[1].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Quinella") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[0].trim() ||
                        (choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[1].trim() &&
                          choosenNumber.split("-")[1].trim() ==
                            drownNumbers.split("")[0].trim()) ||
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split("")[1].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Swinger") {
                      var swingerMatch =
                        drownNumbers.split("")[0].trim() +
                        "," +
                        drownNumbers.split("")[1].trim() +
                        "," +
                        drownNumbers.split("")[2].trim();
                      if (
                        swingerMatch.includes(
                          choosenNumber.split("-")[0].trim()
                        ) &&
                        swingerMatch.includes(
                          choosenNumber.split("-")[1].trim()
                        )
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Trifecta") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split("")[0].trim() &&
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split("")[1].trim() &&
                        choosenNumber.split("-")[2].trim() ==
                          drownNumbers.split("")[2].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Trio") {
                      var swingerMatch =
                        drownNumbers.split("")[0].trim() +
                        "," +
                        drownNumbers.split("")[1].trim() +
                        "," +
                        drownNumbers.split("")[2].trim();
                      if (
                        swingerMatch.includes(
                          choosenNumber.split("-")[0].trim()
                        ) &&
                        swingerMatch.includes(
                          choosenNumber.split("-")[1].trim()
                        ) &&
                        swingerMatch.includes(
                          choosenNumber.split("-")[2].trim()
                        )
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (
                      eachBet == "High" ||
                      eachBet == "Low" ||
                      eachBet == "Even" ||
                      eachBet == "Odd"
                    ) {
                      if (
                        choosenNumber == evenOdd ||
                        choosenNumber == highLow
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    }
                  }
                }
              }
            }

            totalMoneyPaid =
              totalMoneyPaid + this.dailyWork[i].getTickets[t].winner_award;

            if (this.dailyWork[i].getTickets[t].is_cancelled == 0)
              totalTickets = totalTickets + 1;
            if (this.dailyWork[i].getTickets[t].winner_award != 0)
              totalPaidTickets = totalPaidTickets + 1;
          }

          this.dailyReport.push({
            date: this.dailyWork[i].game_date,
            percent: this.dailyWork[i].percent,
            gameNo: this.dailyWork[i].game_number,
            lastTicket:
              this.dailyWork[i].getTickets.length > 0
                ? this.dailyWork[i].getTickets[
                    this.dailyWork[i].getTickets.length - 1
                  ].ticket_date
                : "",
            totalTickets: totalTickets,
            totalPaidTickets: totalPaidTickets,
            cancelledTickets:
              this.dailyWork[i].getTickets.length - totalTickets,
            noBets: noBets,

            totalMoney: totalMoneyOnHand,
            winnerMoney: winnersMoney,
            paidMoney: totalMoneyPaid,
            totalEarning: totalMoneyOnHand - winnersMoney,
          });
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    async getTodaysbingoGameAnalysis() {
      var date2 = "";
      var date1 = "";

      date2 = Date.now();
      date1 =
        new Date(this.formatDate(new Date(Date.now()))).getTime() -
        (24 * 60 * 60 * 1000 + 8683989);

      if (this.loadingCount == 0) this.pageLoading = true;
      try {
        var gameAnalysisResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
               getTodaysGameAnalysis(date1:"${date1}", date2:"${date2}"){
                   game_id                 
                   game_number
                   drawn_numbers
                   game_date
                   high_low
                   even_odd
                   game_status
                   percent
                   getTickets {
                     ticket_id
                     game_id
                     user_id
                     choosen_numbers
                     others
                     ticket_date
                     winner_award
                     is_cancelled
                   }
                    
              }
              
              }
              `,
          },
        });
        this.bingoDailyWork =
          gameAnalysisResult.data.data.getTodaysGameAnalysis;

        this.bingoDailyReport = [];

        for (let i = 0; i < this.bingoDailyWork.length; i++) {
          var totalMoneyOnHand = 0;
          var totalMoneyPaid = 0;
          var totalTickets = 0;
          var totalPaidTickets = 0;
          var noBets = 0;
          var winnersMoney = 0;

          for (let t = 0; t < this.bingoDailyWork[i].getTickets.length; t++) {
            var tickets =
              this.bingoDailyWork[i].getTickets[t].choosen_numbers.split(":");

            var drownNumbers = this.bingoDailyWork[i].drawn_numbers;

            var evenOdd = this.bingoDailyWork[i].even_odd;
            var highLow = this.bingoDailyWork[i].high_low;
            if (this.bingoDailyWork[i].getTickets[t].is_cancelled == 0) {
              if (tickets[0] != "") {
                noBets = noBets + tickets.length;
                for (let j = 0; j < tickets.length; j++) {
                  totalMoneyOnHand =
                    totalMoneyOnHand + parseFloat(tickets[j].split("*")[3]);
                  var eachBet = tickets[j].split("*")[0].trim();

                  var choosenNumber = tickets[j].split("*")[1].trim();

                  var oddValue = parseFloat(tickets[j].split("*")[2].trim());

                  var eachAmount = parseFloat(tickets[j].split("*")[3].trim());

                  if (drownNumbers != "") {
                    if (eachBet == "Win") {
                      if (choosenNumber == drownNumbers.split(" ")[0].trim()) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Place") {
                      if (
                        choosenNumber == drownNumbers.split(" ")[0].trim() ||
                        choosenNumber == drownNumbers.split(" ")[1].trim() ||
                        choosenNumber == drownNumbers.split(" ")[2].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Exacta") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split(" ")[0].trim() &&
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split(" ")[1].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Quinella") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split(" ")[0].trim() ||
                        (choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split(" ")[1].trim() &&
                          choosenNumber.split("-")[1].trim() ==
                            drownNumbers.split(" ")[0].trim()) ||
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split(" ")[1].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Swinger") {
                      var swingerMatch =
                        "," +
                        drownNumbers.split(" ")[0].trim() +
                        "," +
                        drownNumbers.split(" ")[1].trim() +
                        "," +
                        drownNumbers.split(" ")[2].trim() +
                        ",";
                      if (
                        swingerMatch.includes(
                          "," + choosenNumber.split("-")[0].trim() + ","
                        ) &&
                        swingerMatch.includes(
                          "," + choosenNumber.split("-")[1].trim() + ","
                        )
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Trifecta") {
                      if (
                        choosenNumber.split("-")[0].trim() ==
                          drownNumbers.split(" ")[0].trim() &&
                        choosenNumber.split("-")[1].trim() ==
                          drownNumbers.split(" ")[1].trim() &&
                        choosenNumber.split("-")[2].trim() ==
                          drownNumbers.split(" ")[2].trim()
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (eachBet == "Trio") {
                      var swingerMatch =
                        "," +
                        drownNumbers.split(" ")[0].trim() +
                        "," +
                        drownNumbers.split(" ")[1].trim() +
                        "," +
                        drownNumbers.split(" ")[2].trim() +
                        ",";
                      if (
                        swingerMatch.includes(
                          "," + choosenNumber.split("-")[0].trim() + ","
                        ) &&
                        swingerMatch.includes(
                          "," + choosenNumber.split("-")[1].trim() + ","
                        ) &&
                        swingerMatch.includes(
                          "," + choosenNumber.split("-")[2].trim() + ","
                        )
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    } else if (
                      eachBet == "High" ||
                      eachBet == "Low" ||
                      eachBet == "Even" ||
                      eachBet == "Odd"
                    ) {
                      if (
                        choosenNumber == evenOdd ||
                        choosenNumber == highLow
                      ) {
                        winnersMoney = winnersMoney + oddValue * eachAmount;
                      }
                    }
                  }
                }
              }
            }

            totalMoneyPaid =
              totalMoneyPaid +
              this.bingoDailyWork[i].getTickets[t].winner_award;

            if (this.bingoDailyWork[i].getTickets[t].is_cancelled == 0)
              totalTickets = totalTickets + 1;
            if (this.bingoDailyWork[i].getTickets[t].winner_award != 0)
              totalPaidTickets = totalPaidTickets + 1;
          }

          this.bingoDailyReport.push({
            date: this.bingoDailyWork[i].game_date,
            percent: this.bingoDailyWork[i].percent,
            gameNo: this.bingoDailyWork[i].game_number,
            lastTicket:
              this.bingoDailyWork[i].getTickets.length > 0
                ? this.bingoDailyWork[i].getTickets[
                    this.bingoDailyWork[i].getTickets.length - 1
                  ].ticket_date
                : "",
            totalTickets: totalTickets,
            totalPaidTickets: totalPaidTickets,
            cancelledTickets:
              this.bingoDailyWork[i].getTickets.length - totalTickets,
            noBets: noBets,

            totalMoney: totalMoneyOnHand,
            winnerMoney: winnersMoney,
            paidMoney: totalMoneyPaid,
            totalEarning: totalMoneyOnHand - winnersMoney,
          });
        }
      } catch (err) {
        alert(err);
      }
      this.loadingCount++;
      this.pageLoading = false;
    },

    async getOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.adminURL,

          data: {
            query: `{
                getOdds{
                  odds_id
                  choosen_length
                  hits
                  odd_value

                }

              }
              `,
          },
        });

        this.odds = oddsResult.data.data.getOdds;
      } catch (err) {}
    },

    async bingoGetOdds() {
      try {
        var oddsResult = await axios({
          method: "POST",
          url: this.$store.state.bingoAdminURL,

          data: {
            query: `{
                getOdds{
                 	odds_id
		                number
		                color
		                mirror
		                twins
		                final
		                dozen

		                sector
		                high_low_color
		                even_odd
		                high_low
		                neighbors

                }

              }
              `,
          },
        });

        this.bingoOdds = oddsResult.data.data.getOdds;
      } catch (err) {}
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    if (this.$store.state.admin != "") {
      // await this.getTodaysGameAnalysis();
      await this.getTodaysbingoGameAnalysis();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}
.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
